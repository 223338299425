function fillMarkerImage() {
    const markerUrl = document.location.pathname.replace(/^(\/m\/m|\/m\/|\/)/, '');
    document.querySelector('.marker-image').setAttribute('src', `https://html.blippar.com/markers/${markerUrl}`);
}

function fillBlipparAppLink() {
    var mobile = navigator.appVersion.indexOf('iPhone') >= 0 ? 'iPhone' : 'android';
    const androidAppLink = 'https://play.google.com/store/apps/details?id=com.blippar.ar.android';
    const iOsAppLink = 'https://apps.apple.com/us/app/blippar-the-ar-browser/id410604563';
    document.querySelectorAll('.blippar-app-link').forEach(appLink => {
        appLink.setAttribute(
            'href', 
            (mobile === 'iPhone') ? iOsAppLink : androidAppLink
        );
    });
}

fillMarkerImage();
fillBlipparAppLink();
